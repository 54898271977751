::-webkit-scrollbar {
    display: none;
}

.default-btn {
    user-select: none;
    background-color: white;
    padding: 8px;
    padding-inline: 15px;
    margin: 8px;
    border-radius: 10px;
    user-select: none; /* Standard */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

.default-btn:active {
    transform: translateX(2px) translateY(2px);
}
.default-btn:hover {
    opacity: 0.7;
}
